import Icon from '@carbonfact/ui-components/src/Icon';
import { PopoverButton } from '@headlessui/react';
import type { Table } from '@tanstack/react-table';
import { Button } from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import ClickableDropdown from 'app/components/ClickableDropdown';
import SearchBar from 'app/components/SearchBar';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface FilterOption {
  value: string | number | null;
  label?: string;
  desc?: string;
}

interface FilterDimension {
  key: string;
  label: string;
  options: FilterOption[];
  multiselect?: boolean;
}

export type AvailableFilters = FilterDimension[];

// Record of selected dimension keys and their selected values
type SelectedFilters<Filters extends AvailableFilters> = {
  // Mapped type to ensure that the selected filters come from the passed filter options
  [Dimension in Filters[number] as Dimension['key']]?: Dimension['multiselect'] extends true
    ? Dimension['options'][number]['value'][]
    : Dimension['options'][number]['value'];
};

export interface TableFiltersProps<Filters extends AvailableFilters> {
  availableFilters?: Filters;
  selectedFilters?: SelectedFilters<Filters>;
  onFiltersChange?: (filters: SelectedFilters<Filters>) => void;
}

export default function TableFilters<
  RowData,
  Filters extends AvailableFilters,
>({
  // table,
  availableFilters,
  selectedFilters = {},
  onFiltersChange,
}: TableFiltersProps<Filters> & { table: Table<RowData> }) {
  const t = useTranslations();
  const totalFiltersCount = Object.values(selectedFilters)
    .flat()
    .filter((v) => v !== undefined).length;

  if (!availableFilters || availableFilters.length === 0) {
    return null;
  }

  return (
    <ClickableDropdown
      disclosure={
        <Button.Default variant="secondary">
          <Icon
            icon={{
              source: 'hero',
              name: 'FunnelIcon',
              type: totalFiltersCount > 0 ? 'solid' : 'line',
            }}
          />
          {totalFiltersCount > 0 ? (
            <>
              <span className="font-normal">{t('TableFilters.filters')}</span>{' '}
              {totalFiltersCount > 3
                ? `${totalFiltersCount} selected`
                : Object.values(selectedFilters)
                    .flat()
                    .filter((v) => v !== undefined)
                    .map((value) => findValueLabel(availableFilters, value))
                    .join(', ')}
            </>
          ) : (
            <>{t('TableFilters.addFilter')}</>
          )}
        </Button.Default>
      }
      top="top-9"
    >
      <FiltersSelectionWidget
        availableFilters={availableFilters}
        selectedFilters={selectedFilters}
        // TODO: provide a default handler that triggers a local filtering of the table?
        onFiltersChange={(filters) => onFiltersChange?.(filters)}
      />
    </ClickableDropdown>
  );
}

function FiltersSelectionWidget<Filters extends AvailableFilters>({
  availableFilters,
  onFiltersChange,
  selectedFilters: initialSelectedFilters,
}: Required<TableFiltersProps<Filters>>) {
  const [selectedFilters, setSelectedFilters] = useState<
    SelectedFilters<Filters>
  >(initialSelectedFilters);

  useEffect(() => {
    setSelectedFilters({ ...initialSelectedFilters }); // make sure to copy the object from the heap rather than the stack pointer
  }, [initialSelectedFilters]);

  const [shownDimensionKey, setShownDimensionKey] = useState<
    Filters[number]['key'] | null
  >(null);

  const shownDimension: Filters[number] | undefined = availableFilters.find(
    ({ key }) => key === shownDimensionKey,
  );

  const getCountOfSelectedValuesForDimension = useCallback(
    (dimensionKey: typeof shownDimensionKey): number => {
      const selectedValues = Object.entries(selectedFilters).find(
        ([key]) => key === dimensionKey,
      )?.[1];

      const multiselect = availableFilters.find(
        ({ key }) => key === dimensionKey,
      )?.multiselect;

      if (!selectedValues) return 0;
      if (multiselect) {
        if (!Array.isArray(selectedValues)) return 0;
        return selectedValues.length;
      }
      return 1;
    },
    [selectedFilters, availableFilters],
  );

  const isFilterValueSelected = useCallback(
    <D extends Filters[number]>(
      dimension: D,
      dimensionKey: Filters[number]['key'],
      value: D['options'][number]['value'],
    ) => {
      // Single select
      if (!dimension.multiselect) {
        return selectedFilters[dimensionKey] === value;
      }
      // Multiselect
      const dimensionSelectedValues = selectedFilters[dimensionKey];
      if (!Array.isArray(dimensionSelectedValues)) return false;
      return dimensionSelectedValues.includes(value);
    },
    [selectedFilters],
  );

  const selectFilterValue = useCallback(
    <D extends Filters[number]>(
      dimension: D,
      dimensionKey: Filters[number]['key'],
      value: D['options'][number]['value'],
    ) => {
      /*
       * Single-select
       */
      if (!dimension.multiselect) {
        if (selectedFilters[dimensionKey] === value) {
          // Deselect the option
          delete selectedFilters[dimensionKey];
        } else {
          selectedFilters[dimensionKey] =
            value as SelectedFilters<Filters>[typeof dimensionKey]; // we know the value matches the dimension type
        }
        setSelectedFilters({ ...selectedFilters }); // Trigger a re-render by making a new object
        return;
      }

      /*
       * Multi-select
       */

      const dimensionSelectedValues = selectedFilters[dimensionKey];

      if (Array.isArray(dimensionSelectedValues)) {
        const newValue = value;
        const isValueIncluded = dimensionSelectedValues.includes(newValue);

        const updatedValues = isValueIncluded
          ? dimensionSelectedValues.filter((value) => value !== newValue)
          : [...dimensionSelectedValues, newValue];

        setSelectedFilters((prev) => {
          // Remove the dimensionKey entirely if there are no selected values
          if (updatedValues.length === 0) {
            delete prev[dimensionKey];
            return { ...prev }; // Return a new object to properly trigger a re-render
          }

          return {
            ...prev,
            [dimensionKey]: updatedValues,
          };
        });
      } else {
        // Handle the case where we didn't select any values in the dimension yet,
        // = selectedFilters[dimensionKey] is undefined
        setSelectedFilters((prev) => ({
          ...prev,
          [dimensionKey]: [value],
        }));
      }
    },
    [selectedFilters],
  );

  const totalFiltersCount = Object.values(selectedFilters)
    .flat()
    .filter((v) => v !== undefined).length;

  // Enable the apply filters button once changes have been made
  const hasAnyFilterChanged = useMemo(
    () => !isEqual(initialSelectedFilters, selectedFilters),
    [initialSelectedFilters, selectedFilters],
  );

  // Search functionality
  const [optionsSearch, setOptionsSearch] = useState('');
  const t = useTranslations();
  useEffect(() => {
    // Reset search when navigating to another dimension
    if (typeof shownDimensionKey !== 'undefined') {
      setOptionsSearch('');
    }
  }, [shownDimensionKey]);

  return (
    <section className="bg-white p-3 min-w-[330px]">
      <header className="flex flex-row justify-between items-center">
        <div
          className={classNames(
            'flex flex-row justify-start items-center',
            shownDimension && 'cursor-pointer',
          )}
          onClick={() => shownDimension && setShownDimensionKey(null)}
        >
          {shownDimension && (
            <Icon
              height={24}
              width={24}
              icon={{ source: 'local', name: 'direction-left', type: 'line' }}
            />
          )}
          <h3 className="font-medium text-md">
            {shownDimension ? shownDimension.label : 'Filters'}
          </h3>
        </div>

        <Button.Default
          onClick={() => setSelectedFilters({})}
          variant="invisible"
          disabled={totalFiltersCount === 0}
        >
          <span
            className={classNames(
              'text-sm font-normal',
              totalFiltersCount === 0 ? 'text-carbon-400' : 'text-carbon-700',
            )}
          >
            {t('TableFilters.clearAll')}
          </span>
        </Button.Default>
      </header>
      <hr className="mt-2" />
      {
        // Show search bar when there are >10 options
        shownDimension && shownDimension.options.length > 10 && (
          <SearchBar
            className="mt-2"
            value={optionsSearch}
            onChange={setOptionsSearch}
          />
        )
      }
      <ul className="flex flex-col gap-1 my-2 max-h-[345px] overflow-y-auto">
        {shownDimensionKey && shownDimension
          ? /*
             * Show list of dimension options
             */
            shownDimension.options
              .filter((option) => {
                if (!optionsSearch) return true;

                // Apply search
                return String(option.label ?? option.value)
                  .toLowerCase()
                  .includes(optionsSearch.toLowerCase());
              })
              .map((option) => (
                <li
                  key={option.value}
                  className={classNames(
                    'py-1 px-2 flex flex-row flex-nowrap justify-start items-center gap-2 cursor-pointer hover:bg-gray-50 rounded-md',
                    // Show background highlight on selected option for single-select dimension
                    isFilterValueSelected(
                      shownDimension,
                      shownDimensionKey,
                      option.value,
                    ) &&
                      !shownDimension.multiselect &&
                      'bg-gray-50',
                    // On single-select dimensions, align the selected option tick mark to the right
                    !shownDimension.multiselect && 'justify-between',
                  )}
                  onClick={() =>
                    selectFilterValue(
                      shownDimension,
                      shownDimensionKey,
                      option.value,
                    )
                  }
                >
                  {/*
                   * Show checkbox for multi-select dimensions, tick mark for single-select
                   */}
                  {shownDimension.multiselect && (
                    <Checkbox
                      checked={isFilterValueSelected(
                        shownDimension,
                        shownDimensionKey,
                        option.value,
                      )}
                      readOnly // This is a controlled component
                    />
                  )}

                  <span className="text-sm">
                    {option.label ?? option.value}
                  </span>

                  {!shownDimension.multiselect &&
                    isFilterValueSelected(
                      shownDimension,
                      shownDimensionKey,
                      option.value,
                    ) && (
                      <Icon
                        height={18}
                        width={18}
                        icon={{
                          source: 'hero',
                          name: 'CheckIcon',
                          type: 'solid',
                        }}
                      />
                    )}
                </li>
              ))
          : /*
             * Show list of available dimensions
             */
            availableFilters.map((dimension) => (
              <li
                key={dimension.key}
                className="py-1 px-2 flex flex-row flex-nowrap justify-between items-center cursor-pointer hover:bg-gray-50 rounded-md"
                onClick={() => setShownDimensionKey(dimension.key)}
              >
                <span className="text-sm">{dimension.label}</span>
                <div className="flex flex-row justify-end items-center gap-1">
                  {/*
                   * Show count of selected filters within dimension
                   */}
                  {getCountOfSelectedValuesForDimension(dimension.key) > 0 && (
                    <span className="text-sm font-medium px-1 bg-carbon-100 rounded-md max-w-[100px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {getCountOfSelectedValuesForDimension(dimension.key) === 1
                        ? String(
                            findValueLabel(
                              availableFilters,
                              findFirstSelectedValue(
                                selectedFilters,
                                dimension.key,
                              ),
                            ),
                          )
                        : getCountOfSelectedValuesForDimension(dimension.key)}
                    </span>
                  )}
                  <Icon
                    height={20}
                    width={20}
                    icon={{
                      source: 'local',
                      name: 'direction-right',
                      type: 'line',
                    }}
                  />
                </div>
              </li>
            ))}
      </ul>
      <hr className="mb-2" />
      <footer className="flex flex-row flex-nowrap justify-between items-center gap-2">
        <span className="font-medium text-sm whitespace-nowrap text-carbon-500">
          {totalFiltersCount} {t('TableFilters.selectedFilters')}
        </span>
        <PopoverButton
          as={Button.Default}
          onClick={() => onFiltersChange(selectedFilters)}
          disabled={!hasAnyFilterChanged}
          className={classNames(
            hasAnyFilterChanged
              ? 'bg-carbon-500 text-white'
              : 'bg-carbon-100 text-carbon-500',
          )}
        >
          {t('TableFilters.apply')}
        </PopoverButton>
      </footer>
    </section>
  );
}

function findValueLabel<V>(
  availableFilters: AvailableFilters,
  value: V,
): string | V {
  for (const dimension of availableFilters) {
    for (const option of dimension.options) {
      if (option.value === value) {
        return option.label || value;
      }
    }
  }
  return value;
}

function findFirstSelectedValue<Filters extends AvailableFilters>(
  selectedFilters: SelectedFilters<Filters>,
  dimensionKey: Filters[number]['key'],
): string {
  const selectedValues = selectedFilters[dimensionKey];
  if (Array.isArray(selectedValues)) {
    return String(selectedValues.at(0));
  }
  return String(selectedValues ?? 'None');
}
